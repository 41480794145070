import axios from "axios";
import { db, fb } from "@/firebase";

export default {
  methods: {
    async createLedger({
      source_id,
      source_type,
      prevValue = null,
      currentValue = null,
    }) {
      if (source_id == null || source_type == null) return;

      let location = {};
      try {
        location = await axios({
          url: "https://get.geojs.io/v1/ip/geo.json",
        });
      } catch {
        location.data = {};
      }
      db.collection("ledgers").add({
        source_id: source_id,
        source_type: source_type,
        user_id: this.$store.state.user[".key"],
        createdAt: fb.firestore.Timestamp.now(),
        location: location.data,
        prevValue: prevValue,
        currentValue: currentValue,
      });
    },
  },
};
